import Swiper from 'swiper/bundle';

window.addEventListener('load', function () {
  let servicesCarousel = document.querySelector('.service-slider-wrapper');

  if (!servicesCarousel) {
    return;
  }

  servicesCarousel = new Swiper('.service-slider-wrapper', {
    direction: 'horizontal',
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    navigation: {
      nextEl: '.service-slider__nav-next',
      prevEl: '.service-slider__nav-prev',
    },
    pagination: {
      el: '.service-slider__pagination',
      clickable: true,
      renderBullet(index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
    breakpoints: {
      200: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2.5,
        spaceBetween: 32,
      },
    },
  });

  const swiperSlide = document.querySelectorAll('.service-item.swiper-slide');

  swiperSlide.forEach(function (element, index) {
    const swiperSpanNav = document.createElement('p');
    swiperSpanNav.classList.add('service-slider__nav-num--item');
    swiperSpanNav.innerText = '0' + (index + 1);
    swiperSpanNav.dataset.index = index;
    document.querySelector('.swiper-pagination-num').appendChild(swiperSpanNav);
  });

  const swiperNavList = document.querySelectorAll(
    '.service-slider__nav-num--item'
  );

  swiperNavList.forEach(function (el) {
    el.addEventListener('click', function () {
      swiperNavList.forEach(function (e) {
        e.classList.remove('service-slider__nav-num--item-active');
      });
      el.classList.add('service-slider__nav-num--item-active');
      servicesCarousel.slideTo(el.dataset.index);
    });
  });
});
